/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

input, select, textarea {
	vertical-align: middle;
}

html,body {
	-ms-overflow-style: scrollbar;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
th,
td {
  margin: 0;
  padding: 0;
border: 0;
font-size: 12px;
font: inherit;
vertical-align: baseline;
}
body {
  color: #252525;
  cursor: default;
}
div>p{
	margin: 0;
}
div>h1{
	margin: 0;
}
body,
button,
input,
select,
textarea {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}
/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	font-size: 14px;
	color: #575757;
	font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
}

a {
	color: #575757;
	text-decoration: none;
}

.clear {
	clear: both;
}

.clearfix {
	*zoom: 1;
}

.clearfix:after {
	content: "\20";
	clear: both;
	display: block;
}

input {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	resize: none;
	border-radius: 0;
}

/* 去掉input number类型加减按钮 */
input[type='number']{
	-moz-appearance:textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.progress-color {
	height: 100%;
	border-radius: 0.4rem;
	background: #ff8523;
	position: absolute;
	left: 0;
	z-index: 10;
}


/* 阿里symbol图标通用样式 */
.icon {
	width: 1em; 
	height: 1em; 
	vertical-align: -0.15em; 
	fill: currentColor; 
	overflow: hidden;
}
